<template>
    <div id="partnership" class="basic-page" @click="closeLanguageSelection">
        <Loading :show="!imgsLoaded" />
        <div v-show="imgsLoaded">
            <div
                class="static-bg"
                :class="{ hidden: settings && settings.apple }"
            >
                <ImageOptimized
                    v-show="scrolled == 0"
                    :new-src-set="partnerships.bgImageFirstSect"
                    type="static-bg"
                    @loaded="loaded.staticBg0 = true"
                    @failed="loaded.staticBg0 = true"
                />

                <ImageOptimized
                    v-show="scrolled == 1"
                    code="partnership/bg_partnership_perksrequirements"
                    thumb-formats="webp,jpg"
                    thumb-sizes="768,992,1200"
                    type="static-bg"
                    @loaded="loaded.staticBg1 = true"
                    @failed="loaded.staticBg1 = true"
                />
                <ImageOptimized
                    v-show="scrolled == 2"
                    code="partnership/bg_partnership_faq"
                    thumb-formats="webp,jpg"
                    thumb-sizes="768,992,1200"
                    type="static-bg"
                    @loaded="loaded.staticBg2 = true"
                    @failed="loaded.staticBg2 = true"
                />
            </div>
            <!-- <div v-for="partnership in partnerships.rows" :key="partnership.id"> -->
            <div id="header" class="section absolute-bg">
                <div
                    ref="header"
                    class="section-content appearing"
                    :class="{ appeared: headerAppeared }"
                >
                    <div class="upper-title">Partnership</div>
                    <div class="page-title">
                        {{ partnerships.title }}
                    </div>
                    <div class="text">
                        <p v-html="partnerships.first_section"></p>
                        <h2 v-html="partnerships.secondary_title"></h2>
                        <p v-html="partnerships.second_section"></p>
                    </div>
                </div>
            </div>
            <div
                id="partnershipTypes"
                ref="partnershipTypes"
                class="section appearing"
                :class="{
                    appeared: partnershipTypesAppeared,
                    phone: window.w <= 1200,
                }"
            >
                <ImageOptimized
                    v-if="window.w > 1200"
                    :code="partnerships.bgImageSecondSect[0].code"
                    :thumb-formats="
                        `${partnerships.bgImageSecondSect[0].formats}`
                    "
                    :url="getUrl(partnerships.bgImageSecondSect[0].url)"
                    type="partnership-type"
                    @loaded="loaded.partnershipTypesBg = true"
                    @failed="loaded.partnershipTypesBg = true"
                />
                <div class="section-content">
                    <div class="first">
                        <ImageOptimized
                            v-if="window.w <= 1200"
                            :code="partnerships.bgImageSecondSect[0].code"
                            :thumb-formats="
                                `${partnerships.bgImageSecondSect[0].formats}`
                            "
                            :url="getUrl(partnerships.bgImageSecondSect[0].url)"
                            type="partnership-type left"
                            @loaded="loaded.partnershipTypesBg = true"
                            @failed="loaded.partnershipTypesBg = true"
                        />
                    </div>
                    <div class="second">
                        <ImageOptimized
                            v-if="window.w <= 1200"
                            :code="partnerships.bgImageSecondSect[0].code"
                            :thumb-formats="
                                `${partnerships.bgImageSecondSect[0].formats}`
                            "
                            :url="getUrl(partnerships.bgImageSecondSect[0].url)"
                            type="partnership-type right"
                            @loaded="loaded.partnershipTypesBg = true"
                            @failed="loaded.partnershipTypesBg = true"
                        />
                    </div>
                    <div class="partners-button">
                        <LinkButton
                            text="Vigor Partners"
                            wrapper-type="noPadding"
                            type="red minWidth"
                            :link="pages.PARTNERS_PAGE_NAME"
                        />
                        <div
                            class="text"
                            v-html="partnerships.button_caption"
                        ></div>
                    </div>
                </div>
            </div>

            <div
                id="requirements"
                ref="requirements"
                class="section absolute-bg dark"
                style="padding-top: 20px; padding-bottom: 120px;"
            >
                <div
                    class="section-content appearing"
                    :class="{ appeared: requirementsAppeared }"
                    v-html="partnerships.third_section"
                ></div>
            </div>

            <div
                id="form"
                ref="form"
                class="section appearing"
                :class="{ appeared: formAppeared }"
            >
                <ImageOptimized
                    :img-style="'height: 100%'"
                    code="partnership/bg_partnership_form"
                    thumb-formats="webp,png"
                    thumb-sizes="480,768,992,1200"
                    type="partnership-form"
                    @loaded="loaded.formBg = true"
                    @failed="loaded.formBg = true"
                />
                <div class="section-content">
                    <h2>Apply now!</h2>
                    <form @submit.prevent="handleSubmit">
                        <div class="half">
                            <input
                                v-model="form.name"
                                type="text"
                                placeholder="Name*"
                                :class="{
                                    alertRequired: alertRequired,
                                    empty: !form.name,
                                }"
                            />
                            <input
                                v-model="form.email"
                                type="email"
                                placeholder="Email*"
                                :class="{
                                    alertRequired: alertRequired,
                                    empty: !form.email,
                                }"
                            />
                            <input
                                v-model="form.channelName"
                                type="text"
                                placeholder="Channel Name*"
                                :class="{
                                    alertRequired: alertRequired,
                                    empty: !form.channelName,
                                }"
                            />
                            <div
                                class="custom-select"
                                :class="{
                                    alertRequired: alertRequired,
                                    empty: !form.language && !selectingLanguage,
                                }"
                                @click="openLanguageSelection"
                            >
                                <div class="custom-select-value">
                                    <template v-if="!form.language"
                                        >Channel Language*</template
                                    >
                                    <template v-else>{{
                                        selectedLanguage.name
                                    }}</template>
                                </div>
                                <div
                                    v-if="selectingLanguage"
                                    class="custom-options"
                                >
                                    <div
                                        v-for="(language, code) in languages"
                                        :key="code"
                                        class="custom-option"
                                        @click="
                                            selectLanguage({
                                                name: language.name,
                                                code: code,
                                            })
                                        "
                                    >
                                        {{ language.name }}
                                    </div>
                                </div>
                            </div>
                            <input
                                v-model="form.channelUrl"
                                type="text"
                                placeholder="Channel URL*"
                                :class="{
                                    alertRequired: alertRequired,
                                    empty: !form.channelUrl,
                                }"
                            />
                            <input
                                v-model="form.channelUrl2"
                                type="text"
                                placeholder="Channel URL 2"
                                :class="{ empty: !form.channelUrl2 }"
                            />
                            <input
                                v-model="form.channelUrl3"
                                type="text"
                                placeholder="Channel URL 3"
                                :class="{ empty: !form.channelUrl3 }"
                            />
                            <input
                                v-model="form.twitter"
                                type="text"
                                placeholder="Twitter"
                                :class="{ empty: !form.twitter }"
                            />
                        </div>
                        <div class="half">
                            <span :class="{ empty: !form.motivation }"
                                >Why do you want to become Vigor partner?*</span
                            >
                            <textarea
                                v-model="form.motivation"
                                :class="{
                                    alertRequired: alertRequired,
                                    empty: !form.motivation,
                                }"
                            ></textarea>
                            <br />
                            <span :class="{ empty: !form.frequency }"
                                >How often do you create Vigor Content?*</span
                            >
                            <textarea
                                v-model="form.frequency"
                                :class="{
                                    alertRequired: alertRequired,
                                    empty: !form.frequency,
                                }"
                            ></textarea>
                            <br />
                            <span :class="{ empty: !form.commentary }"
                                >Do you have question or comments?</span
                            >
                            <textarea
                                v-model="form.commentary"
                                :class="{ empty: !form.commentary }"
                            ></textarea>
                        </div>
                        <div class="form-end">
                            * - required fields
                            <div>
                                <input
                                    ref="checkbox"
                                    class="checkbox"
                                    type="checkbox"
                                />
                                <span class="checkbox-label">
                                    I have read and agree to
                                    <a
                                        class="a-text"
                                        href="https://vigorgame.com/eula"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        >the terms of use</a
                                    >.
                                </span>
                            </div>

                            <div class="captcha">
                                <vue-recaptcha
                                    v-if="recaptchaSettings"
                                    ref="recaptcha"
                                    size="invisible"
                                    badge="inline"
                                    :sitekey="recaptchaSettings.key"
                                    @verify="onVerify"
                                    @expired="onExpired"
                                />
                            </div>

                            <div
                                class="form-button"
                                :class="{
                                    showAlert: alertTerms || alertRequired,
                                }"
                            >
                                <LinkButton
                                    text="Apply"
                                    type="red minWidth"
                                    :form="true"
                                />
                                <div
                                    v-if="alertTerms && !alertRequired"
                                    class="alert"
                                >
                                    You need to agree to the terms of use.
                                </div>
                                <div v-if="alertRequired" class="alert">
                                    You need to fill in the required fields.
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div
                id="faq"
                ref="faq"
                class="section absolute-bg"
                :style="{
                    'min-height':
                        (settings && settings.apple && window.w >= 1400
                            ? 900
                            : faqHeight) + 'px',
                }"
                :class="{
                    'two-columns':
                        settings && settings.apple && window.w >= 1400,
                }"
            >
                <div
                    ref="faqSection"
                    class="section-content appearing"
                    :class="{ appeared: faqAppeared }"
                >
                    <div v-html="partnerships.faqs"></div>
                </div>
            </div>
        </div>

        <Popup
            :show="popupPartner"
            title="Thank you for applying"
            text="Your Partnership application has been submitted."
            @closeMethod="popupPartnerClose"
        />
    </div>
</template>

<script>
import Loading from '@/components/partials/Loading';
import LinkButton from '@/components/partials/LinkButton';
var languages = require('./languages.json');
import { PartnerResource } from '@/services/partner';
import VueRecaptcha from 'vue-recaptcha';
import { getCaptcha } from '../../services/captcha';
import Popup from '@/components/partials/popups/Popup';
import ImageOptimized from '@/components/partials/ImageOptimized';
import { mapGetters } from 'vuex';
import * as pages from '@/routes/pageCodes';

export default {
    name: 'Partnership',
    components: { Loading, LinkButton, VueRecaptcha, Popup, ImageOptimized },
    data() {
        return {
            name: 'Partnership',
            languages,
            scrolled: 0,
            lastScrolled: null,
            playTrailerOn: false,
            window: { w: window.innerWidth, h: window.innerHeight },

            loaded: {
                staticBg0: false,
                staticBg1: false,
                staticBg2: false,
                partnershipTypesBg: false,
                formBg: false,
            },

            //TODO: turn this on?
            headerAppeared: true,
            partnershipTypesAppeared: true,
            requirementsAppeared: true,
            formAppeared: true,
            faqAppeared: true,

            form: {
                name: '',
                email: '',
                channelName: '',
                channelUrl: '',
                channelUrl2: '',
                channelUrl3: '',
                language: '',
                twitter: '',
                motivation: '',
                frequency: '',
                commentary: '',
            },

            selectingLanguage: false,
            selectedLanguage: { name: '', code: '' },

            popupPartner: false,
            alertTerms: false,
            alertRequired: false,

            captchaResponse: null,
            recaptchaSettings: null,

            faqHeight: 1000,
            pages,
        };
    },
    computed: {
        imgsLoaded() {
            return (
                this.loaded.staticBg0 &&
                this.loaded.staticBg1 &&
                this.loaded.staticBg2 &&
                this.loaded.partnershipTypesBg &&
                this.loaded.formBg
            );
        },
        widthFit() {
            return this.window.w / this.window.h > 0.71;
        },
        streamerKit() {
            return 'https://data.bistudio.com/press/vigor/Vigor_streamer_kit.zip';
        },
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        ...mapGetters('partnerships', {
            partnerships: 'getPartnerships',
        }),
    },
    created() {
        getCaptcha().then(response => {
            this.recaptchaSettings = response;
        });
        window.addEventListener('scroll', this.handleScroll, { passive: true });
        window.addEventListener('resize', this.windowResize);
        this.handleScroll();
    },
    mounted() {
        setTimeout(() => {
            this.recountFaqHeight();
            this.handleBg();
        }, 500);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll, {
            passive: true,
        });
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        getUrl(url) {
            return url.substring(0, url.lastIndexOf('/') + 0);
        },
        reverseItems(imgs) {
            return imgs.slice().reverse();
        },
        getScrolledPosition(scroll, margin) {
            if (scroll > this.$refs.header.clientHeight + margin) {
                this.scrolled = 1;
            }
            if (
                scroll >
                this.$refs.header.clientHeight +
                    this.$refs.partnershipTypes.clientHeight +
                    this.$refs.requirements.clientHeight +
                    margin
            ) {
                this.scrolled = 2;
            }
        },
        handleScroll() {
            this.scrolled = 0;
            let scroll =
                window.scrollY === undefined
                    ? document.documentElement.scrollTop
                    : window.scrollY;

            if (
                !(
                    this.$refs.header &&
                    this.$refs.partnershipTypes &&
                    this.$refs.requirements &&
                    this.$refs.form
                )
            ) {
                return;
            }

            if (this.window.w <= 1200) {
                this.getScrolledPosition(scroll, 100);
            } else {
                this.getScrolledPosition(scroll, 500);
            }

            let position =
                (window.scrollY === undefined
                    ? document.documentElement.scrollTop
                    : window.scrollY) +
                window.innerHeight * 0.5;

            if (this.$refs.header)
                this.headerAppeared = this.shouldAppear(
                    position -
                        this.$refs.header.offsetTop -
                        this.$refs.header.clientHeight * 0.5
                )
                    ? true
                    : this.headerAppeared;

            if (this.$refs.partnershipTypes)
                this.partnershipTypesAppeared = this.shouldAppear(
                    position - this.$refs.partnershipTypes.offsetTop
                )
                    ? true
                    : this.partnershipTypesAppeared;

            if (this.$refs.requirements)
                this.requirementsAppeared = this.shouldAppear(
                    position - this.$refs.requirements.offsetTop
                )
                    ? true
                    : this.requirementsAppeared;

            if (this.$refs.form)
                this.formAppeared = this.shouldAppear(
                    position - this.$refs.form.offsetTop
                )
                    ? true
                    : this.formAppeared;

            if (this.$refs.faq)
                this.faqAppeared = this.shouldAppear(
                    position - this.$refs.faq.offsetTop
                )
                    ? true
                    : this.faqAppeared;

            this.recountFaqHeight();
            this.handleBg();
        },
        shouldAppear(
            position,
            intervalTo = window.innerHeight * 0.5,
            intervalFrom = 0
        ) {
            return intervalFrom <= position && position <= intervalTo;
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.recountFaqHeight();
            this.handleBg();
        },
        recountFaqHeight() {
            this.faqHeight = this.$refs.faqSection.clientHeight + 150;
        },
        handleBg() {
            if (this.settings && this.settings.apple) {
                if (this.scrolled == this.lastScrolled) return;
                this.lastScrolled = this.scrolled;

                let bg = 'bg_partnership_intro';
                let top = 0;
                let marginTop = 0;
                let w = this.window.w;
                switch (this.scrolled) {
                    case 2:
                        bg = 'bg_partnership_faq';
                        if (w >= 1920) {
                            marginTop = 350;
                        } else if (w > 1400) {
                            marginTop = 200;
                        } else if (w > 1200) {
                            marginTop = 0;
                        }
                        top =
                            this.$refs.header.clientHeight +
                            this.$refs.partnershipTypes.clientHeight +
                            this.$refs.requirements.clientHeight +
                            this.$refs.form.clientHeight +
                            marginTop;
                        break;
                    case 1:
                        bg = 'bg_partnership_perksrequirements';
                        if (w > 1400) {
                            marginTop = 0;
                        } else if (w > 1200) {
                            marginTop = -900;
                        } else if (w > 700) {
                            marginTop = -200;
                        } else {
                            marginTop = -900;
                        }
                        top =
                            this.$refs.header.clientHeight +
                            this.$refs.partnershipTypes.clientHeight +
                            marginTop;
                        break;
                    case 0:
                    default:
                        bg = 'bg_partnership_intro';
                        top = 0;
                        break;
                }

                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/partnership/` +
                        bg +
                        `.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px ` +
                        top +
                        `px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        openLanguageSelection(event) {
            this.selectingLanguage = !this.selectingLanguage;
            event.stopPropagation();
        },
        closeLanguageSelection() {
            this.selectingLanguage = false;
        },
        selectLanguage(language) {
            this.selectingLanguage = false;
            this.selectedLanguage = language;
            this.form.language = language.code;
            event.stopPropagation();
        },
        popupPartnerClose() {
            this.popupPartner = false;
            location.reload();
        },
        onVerify(value) {
            this.captchaResponse = value;
            this.sendForm();
        },
        onExpired() {
            this.$refs.recaptcha.reset();
            this.captchaResponse = null;
        },
        onExecute() {
            if (!this.$refs.checkbox.checked) {
                this.alertTerms = true;
                return;
            }
            if (
                !(
                    this.form.name &&
                    this.form.email &&
                    this.form.channelName &&
                    this.form.language &&
                    this.form.channelUrl &&
                    this.form.motivation &&
                    this.form.frequency
                )
            ) {
                this.alertRequired = true;
                return;
            }

            this.alertTerms = false;
            this.alertRequired = false;
            this.$refs.recaptcha.execute();
        },
        handleSubmit() {
            this.onExecute();
        },
        sendForm() {
            /*
                this.$validator.validateAll('partnership').then((valid) => {
                    if (valid) {
                        */
            PartnerResource.add({
                partner: this.form,
                'g-recaptcha-response': this.captchaResponse,
            })
                .then(response => {
                    this.popupPartner = true;

                    this.form = {
                        name: '',
                        email: '',
                        channelName: '',
                        channelUrl: '',
                        channelUrl2: '',
                        channelUrl3: '',
                        language: '',
                        twitter: '',
                        motivation: '',
                        frequency: '',
                        commentary: '',
                    };
                    this.$refs.checkbox.checked = false;
                })
                .catch(err => {
                    console.log(err);
                    //this.EventBus.$emit(this.events.CAPTCHA_RESET);
                });
            //}
            //});
        },
        scrollToForm() {
            document
                .getElementById('form')
                .scrollIntoView({ behavior: 'smooth' });
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';

#partnership {
    font-size: 18px;
    color: $light;

    .section {
        &#header {
            z-index: 0;
            height: 1000px;

            .section-content {
                padding-top: 192px;

                .text {
                    width: 540px;
                    max-width: 80vw;
                }
            }
            @media (max-width: 1200px) {
                height: 1650px;
            }

            @media (max-width: 1024px) {
                height: 1350px;
            }

            @media (max-width: 700px) {
                height: 1250px;
            }

            .buttons {
                .button-wrapper {
                    display: inline-block;
                    padding-bottom: 10px;
                }
            }
        }

        &#partnershipTypes {
            display: block;
            z-index: 1;
            overflow: visible;
            background-color: black;
            margin-bottom: 10vw;

            .section-content {
                padding: 0 5vw;
                position: relative;
                // width: 90vw;
                margin-top: -53vw;

                .first,
                .second {
                    width: 33vw;
                    display: inline-block;
                    vertical-align: top;
                    margin: 5vw;
                    margin-top: 30vw;
                    min-height: 20vw;
                }

                .partners-button {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    text-align: center;
                    margin: 5vw 0;
                }

                @media (max-width: 600px) {
                    .partners-button /deep/ .text {
                        margin: 4vw 2vw;
                        word-break: normal;
                        font-size: 15px;

                        p {
                            word-wrap: break-word;
                        }
                    }
                }
            }

            .section-content::before {
                content: '';
                background: url('/static/images/partnership/bg_tiers_top.png');
                background-size: cover;
                width: 100vw;
                height: 6vw;
                top: -6vw;
                left: 0;
                position: absolute;
                display: block;
                z-index: 10000;
            }

            .section-content::after {
                content: '';
                background: url('/static/images/partnership/bg_tiers_bottom.png');
                background-size: cover;
                width: 100vw;
                height: 6vw;
                bottom: -5vw;
                left: 0;
                position: absolute;
                display: block;
            }

            @media (min-width: 1920px) {
                margin-bottom: 192px;

                .section-content {
                    padding: 0 96px;
                    margin-top: -1020px;

                    .first,
                    .second {
                        width: 576px;
                        margin: 96px;
                        margin-top: 576px;
                    }
                }

                .section-content::before {
                    width: 1920px;
                    height: 110px;
                    top: -110px;
                }

                .section-content::after {
                    width: 1920px;
                    height: 110px;
                    bottom: -100px;
                }
            }

            &.phone {
                .first {
                    display: none;
                }
                .second {
                    display: block;
                    width: 80vw;
                    margin: 30vw 5vw 5vw 5vw;

                    br {
                        display: none;
                    }

                    .text {
                        margin-top: -50vw;
                    }
                }
                .second {
                    margin-top: 0;
                    padding-bottom: 5vw;
                }
            }
        }
        &#requirements {
            height: 30vw;
            z-index: 1;

            /deep/ .third-half {
                width: 40vw;
                display: inline-block;
                vertical-align: top;
                h2 {
                    color: $red;
                    margin-bottom: 20px;

                    &.capitals {
                        text-transform: uppercase;
                    }

                    &.light {
                        color: $light;
                    }
                }
            }

            /deep/ .third-half:first-child {
                width: 35vw;
                margin-right: 5vw;
            }

            .section-content {
                display: flex;
                @media (max-width: 1024px) {
                    flex-direction: column;
                }
            }

            @media (min-width: 1920px) {
                height: 530px;
                /deep/ .third-half {
                    width: 768px;
                }

                /deep/.third-half:first-child {
                    width: 672px;
                    margin-right: 96px;
                }
            }

            @media (max-width: 1400px) {
                height: 55vw;

                .section-content {
                    margin: 5vw 0;
                }
            }

            @media (max-width: 1024px) {
                height: 120vw;

                /deep/ .third-half {
                    display: block;
                    width: 80vw;
                    margin-bottom: 20px;
                }

                /deep/ .third-half:first-child {
                    display: block;
                    width: 80vw;
                    margin-bottom: 20px;
                }
            }

            @media (max-width: 700px) {
                height: 230vw;

                .section-content {
                    margin: 30vw 0;
                }
            }

            @media (max-width: 500px) {
                height: 290vw;

                .section-content {
                    margin: 30vw 0;
                }
            }

            @media (max-width: 400px) {
                height: 400vw;

                .section-content {
                    margin: 30vw 0;
                }
            }

            @media (max-width: 360px) {
                height: 435vw;

                .section-content {
                    margin-top: 15vw;
                }
            }

            @media (max-width: 300px) {
                height: 580vw;
            }

            @media (max-width: 250px) {
                height: 1000vw;
            }

            /deep/ .third-content-item {
                padding-left: 25px;
                margin-bottom: 10px;

                a {
                    text-decoration: none;
                    color: #000;
                    border-bottom: 1px solid #000;
                    font-weight: 600;
                    display: inline-block;
                    margin: 0;
                }
                a:hover {
                    color: red;
                }
            }

            /deep/ .third-content-item::before {
                content: '';
                background: url('/static/images/presskit/bullet.svg');
                height: 12px;
                width: 12px;
                display: inline-block;
                margin-left: -25px;
                margin-right: 14px;
            }
        }
        &#form {
            height: 98.5vw;
            min-height: 1200px;
            max-height: 1500px;
            bottom: 5vw;

            z-index: 1;
            text-align: center;
            overflow: visible;

            .bg-image {
                height: 78.5vw;
                min-height: 1200px;
                max-height: 1500px;
            }

            @media (min-width: 1920px) {
                bottom: 0px;
                height: 1500px;

                .bg-image {
                    height: 1500px;
                }
            }

            .section-content {
                width: 100vw;
                max-width: 100vw;
                top: 10vw;
                padding: 0;
                display: inline-block;
                position: absolute;
                bottom: 0;
                left: 0;
                text-align: center;

                .form-end {
                    text-align: center;
                    margin: 0 10vw;
                }

                @media (min-width: 1920px) {
                    top: 300px;
                    width: 1920px;

                    .form-end {
                        margin: 0 192px;
                    }
                }

                @media (max-width: 1400px) {
                    min-height: 2000px;

                    .half {
                        display: block;
                        width: 80vw;
                        margin: 0 10vw;
                    }
                }

                @media (max-width: 1000px) {
                    .half {
                        display: block;
                        width: 80vw;
                    }
                }
            }
            .custom-select {
                position: relative;
            }
            input,
            textarea,
            .custom-select {
                background-color: transparent;
                color: $light !important;
                font-size: 20px;
                font-family: inherit;
                width: calc(100% - 40px);
                height: 50px;
                margin: 15px;
                padding: 0 20px;
                display: block;
                border: 1px solid $light;
                resize: none;
                opacity: 1;
                text-align: left;
                line-height: 50px;

                @media (max-width: 300px) {
                    font-size: 16px;
                }
            }

            .custom-select-value {
                border: none;
                position: relative;
                width: 100%;
            }

            .custom-select-value:after {
                content: '';
                width: 30px;
                height: 30px;
                margin: 10px 0;
                bottom: 0;
                right: 0;
                position: absolute;
                display: inline-block;
                background-image: url('/static/images/partnership/dropdown_arrow.svg');
                background-position: center center;
                background-size: cover;
            }

            .custom-options {
                display: block;
                background-color: $light;
                color: $dark;
                height: 400px;
                overflow-y: scroll;

                margin-left: -21px;
                width: calc(100% + 40px);
                border: 1px solid $light;
                font-weight: 600;

                .custom-option {
                    padding: 0 20px;

                    &:hover {
                        background-color: #cccccc;
                    }
                }
            }

            input,
            textarea,
            .custom-select {
                color: $light !important;

                ::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $light;
                    opacity: 1; /* Firefox */
                }

                :-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: $light;
                }

                ::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: $light;
                }

                &.empty {
                    color: rgba(255, 255, 255, 0.8) !important;
                    border: 1px solid rgba(255, 255, 255, 0.5);

                    &.alertRequired {
                        border: 1px solid $red;
                    }

                    ::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: rgba(255, 255, 255, 0.8);
                        opacity: 1; /* Firefox */
                    }

                    :-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: rgba(255, 255, 255, 0.8);
                    }

                    ::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: rgba(255, 255, 255, 0.8);
                    }

                    .custom-select-value:after {
                        opacity: 0.5;
                    }
                }
            }

            span,
            .custom-select {
                //border: none;
                color: $light !important;
                &.empty {
                    color: rgba(255, 255, 255, 0.5) !important;
                }
            }

            textarea {
                height: 200px;

                &.big {
                    height: 285px;
                }
            }

            .checkbox {
                height: 20px;
                width: 20px;
                display: inline-block;
            }

            .checkbox-label {
                display: inline-block;
            }

            .form-button {
                margin: 20px 0;
                position: relative;

                .alert {
                    color: $red;
                    display: none;
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    bottom: -30px;
                    left: 0;
                    font-weight: 600;

                    @media (max-width: 400px) {
                        bottom: -60px;
                    }
                }

                &.showAlert {
                    .alert {
                        display: block;
                    }
                }
            }

            .captcha {
                margin-left: calc(50vw - 128px - 10vw);

                @media (min-width: 1920px) {
                    margin-left: 630px;
                }
            }

            @media (max-width: 1400px) {
                min-height: 2000px;

                /deep/ .third-half {
                    display: block;
                    width: 80vw;
                    margin: 0 10vw;
                    padding: 0;
                }

                input,
                textarea,
                .custom-select {
                    width: calc(80vw - 30px);
                    margin: 15px 0;

                    &.checkbox {
                        margin: 15px;
                    }
                }
            }
        }
        &#faq {
            z-index: 0;
            /deep/ .faqs-content {
                margin-top: 50px;
                display: inline-block;
                width: 750px;
                max-width: 80vw;
                vertical-align: top;
                p {
                    a {
                        text-decoration: none;
                        color: #fff;
                        font-weight: 600;
                        border-bottom: 1px solid #fff;
                        margin: 0;
                    }
                    a:hover {
                        color: red;
                    }
                }

                h2 {
                    color: #ed1c24;
                    margin-bottom: 10px;
                    text-transform: uppercase;
                }
            }
            /deep/ .page-title {
                font-size: 72px;
                font-weight: 700;
                text-transform: uppercase;
                padding: 15px 0;
                margin-bottom: 0;
            }

            .section-content {
                padding-top: 100px;

                .content {
                    margin-top: 50px;

                    .faq-block {
                        display: inline-block;
                        width: 750px;
                        max-width: 80vw;
                        vertical-align: top;

                        .faq-half {
                            display: block;
                        }
                    }
                }
            }

            &.two-columns {
                @media (min-width: 1200px) {
                    height: 900px;

                    .section-content {
                        .content {
                            .faq-block {
                                display: block;
                                width: 100%;

                                .faq-half {
                                    display: inline-block;
                                    vertical-align: top;
                                    width: calc(50% - 100px);
                                    margin-right: 100px;

                                    @media (min-width: 1920px) {
                                        width: 660px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
